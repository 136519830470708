import React, { Component } from 'react';
import moment from 'moment';
import contentData from '../content.json';

class CountDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countdowndate: contentData.countDown.countDownToDate,
            displayCountDown: '00:00:00:00',
            displayDay1: '0',
            displayDay2: '0',
            displayHour1: '0',
            displayHour2: '0',
            displayMinute1: '0',
            displayMinute2: '0',
            displaySecond1: '0',
            displaySecond2: '0',
            timeUntillTwitchCon: '',
            countDownInterval: false
        };

        this.updateDisplayCountDown = this.updateDisplayCountDown.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    countDown() {

        this.timeUntill = null;
        this.timeArray = [];
        this.val = moment(this.state.timeUntillTwitchCon).subtract('seconds', 1);

        if (this.val) {

            this.setState({
                timeUntillTwitchCon: this.val
            });

            // format and split into an array for individual elements.
            this.timeUntill = moment.utc(this.state.timeUntillTwitchCon).format('DD:HH:mm:ss');
            this.timeArray = this.timeUntill.split(':');

            // may be a more elegant soultion to this but for now i'm setting each digit as a seperate state varible

            // days
            this.setState({
                displayDay1: this.timeArray[0].charAt(0)
            });
            this.setState({
                displayDay2: this.timeArray[0].charAt(1)
            });

            // hours
            this.setState({
                displayHour1: this.timeArray[1].charAt(0)
            });
            this.setState({
                displayHour2: this.timeArray[1].charAt(1)
            });

            //minutes
            this.setState({
                displayMinute1: this.timeArray[2].charAt(0)
            });
            this.setState({
                displayMinute2: this.timeArray[2].charAt(1)
            });

            // seconds
            this.setState({
                displaySecond1: this.timeArray[3].charAt(0)
            });
            this.setState({
                displaySecond2: this.timeArray[3].charAt(1)
            });

        }
        else {
            console.log('Reveal Content TBA');
        }
    }

    updateDisplayCountDown() {
        let now = moment().local();
        let twitchConDate = moment(this.state.countdowndate, 'YY-DD-MM HH:mm');
        this.setState({
            timeUntillTwitchCon: twitchConDate.diff(now)
        });
    }

    componentDidMount() {
        this.updateDisplayCountDown();
        this.interval = setInterval(this.countDown, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="count-down">
                <div className="counter-title">{contentData.countDown.label}</div>
                <ul className="counter">
                    <li className="number">{this.state.displayDay1}</li>
                    <li className="number">{this.state.displayDay2}</li>
                    <li className="spacer"><img src="images/counter-spacer.png" alt="spacer" /> </li>
                    <li className="number">{this.state.displayHour1}</li>
                    <li className="number">{this.state.displayHour2}</li>
                    <li className="spacer"><img src="images/counter-spacer.png" alt="spacer" /> </li>
                    <li className="number">{this.state.displayMinute1}</li>
                    <li className="number">{this.state.displayMinute2}</li>
                    <li className="spacer"><img src="images/counter-spacer.png" alt="spacer" /> </li>
                    <li className="number">{this.state.displaySecond1}</li>
                    <li className="number">{this.state.displaySecond2}</li>
                </ul>
            </div>
        );
    }
}

export default CountDown;