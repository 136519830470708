import React, { Component } from 'react';
import { Link } from "@reach/router"
import contentData from '../content.json';

class Header extends Component {

  render() {
    return (
    <header className="header"> 

        <div className="header-bg">
            <div className="header-logo"> <Link to="/"> <img src={contentData.header.logo} alt="wormhole logo" /> </Link> </div>
        </div>
        
        <div className="lense-flare-top">
          <img src='images/lense-flare-top.png' alt="lense flare" /> 
        </div>

    </header>
    );
  }
}
export default Header;