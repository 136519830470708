import React, { Component } from 'react';
import $ from 'jquery';
import CountDown from '../components/CountDown';
import VideoContentPane from '../components/VideoContentPane';
import SlideContentPane from '../components/SlideContentPane';

import contentData from '../content.json';


class Hero extends Component {
  constructor(props) {
    super(props);
    this.imgUrl = 'images/background-image.jpg';
    this.divStyle = {
      backgroundImage: 'url(' + this.imgUrl + ')',
    };
  
    this.scrollToRegister = this.scrollToRegister.bind(this);
  }

  scrollToRegister(){
    $('html, body').animate({
        scrollTop: parseInt($("#footer").offset().top)
    }, 500);
  }

  render() {
    return (
    <div className="hero" style={this.divStyle}>
      
      <div className="hero-container">

        <div className="count-down-container">
          <CountDown />
        </div>

        <div className="hero-title">
          <img src={contentData.hero.headerImage} alt="The World is the Game" /> 
        </div>

        <h3>{contentData.hero.subTitleCopy}</h3>
        <button className='hero-cta' onClick={this.scrollToRegister}>Sign Up Now!</button>

        <div className="video-container">
            <VideoContentPane />
        </div>

        <div className="slide-container">
            <SlideContentPane />
        </div>
      
      </div>


    </div>
    );
  }
}
export default Hero;