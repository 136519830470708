import React, { Component } from 'react';

class privacyPolicy extends Component {
  componentDidMount() {
    requestAnimationFrame(function () {
      window.scrollTo(0, 0);
    });
  }
  render() {
    return (
      <div className="privacyPolicy body-copy content">
        <h1>Privacy Policy</h1>
        <p>Thank you for using the services available through the TapThere mobile application and the website at the domain name Tapthere.com, each, that are provided by TapThere, Inc. (the TapThere Service). At TapThere, we strive to make your online experience with us secure and satisfying. This Privacy Notice describes and governs what information we gather from you, how we use that information, and what we do to protect it. By using the TapThere Service, you expressly consent to the information handling practices described in this Policy. If you do not want information about you to be used in the manner set forth in this Privacy Notice, please do not use the TapThere Service. This Privacy Notice is expressly incorporated into our Terms of Use. Your use of the TapThere Service and any personal information you provide through the TapThere Service are subject to this Privacy Notice and the Terms of Use at all times.</p>
        <h3>Changes and Updates</h3>
        <p>We may periodically revise this Privacy Notice. Please revisit this page to stay current with any changes. The most recent version of this Policy will always be available on the TapThere Service. The effective date of any changes will be posted at the top of this page. For changes to this Privacy Notice that may be materially less restrictive on our use or disclosure of personal information you have provided to us, we will obtain your consent before implementing the change. Your continued use of the TapThere Service constitutes your agreement to this Privacy Notice and any future revisions.</p>
        <h3>Information TapThere Collects</h3>
        <p>TapThere collects two types of data about users: (1) information that you or others voluntarily submit to us (described below under User-Supplied Information) and (2) technical data automatically collected from all visitors to the TapThere Service (described below under Automatically Collected Data).</p>
        <p><strong>User-Supplied Information:</strong>&nbsp;In using the TapThere Service, for example when you register with us, or if you send us an email, you will provide TapThere with data generally called personally identifiable information. If you choose to provide such information, you are giving TapThere permission to use and store that information consistent with this Privacy Notice. Additionally, please know that when you register or sign in to the TapThere Service, you are not anonymous to us.</p>
        <ul>
          <li><strong>Mobile device information:</strong>&nbsp;We may store your phone number, as well as information regarding your mobile device, including your mobile phone number, device type and operating system and information in your mobile phones address book or in other applications as permitted by the functionality of such mobile device.</li>
          <li><strong>Profile Information:</strong>&nbsp;To register for the TapThere Service, you will need to provide information through the online registration form on our website at TapThere.com or to provide access to the information on your Facebook account through Facebook Connect. By doing so, you understand and authorize us to use all the information exposed to us through our website and/or Facebook Connect pursuant to this Privacy Notice. Where you use Facebook Connect to register, this includes your status updates, photos, videos, name, email address and information regarding your friends on Facebook. You should check your Facebook settings to change the information sent to us through Facebook Connect.</li>
          <li><strong>Location:</strong>&nbsp;You may allow the TapThere mobile application to have access to your location to provide helpful information and services based on where you are located (i.e., by allowing your mobile device to send us your location).</li>
        </ul>
        <p><strong>Automatically Collected Data:</strong>&nbsp;The TapThere Service operating on a website automatically logs non-personally identifiable information such as Internet Protocol address (IP Address), a unique user ID, the content and pages that you access on the TapThere Service and the dates and times that you visit the TapThere Service. In addition, if you come to the TapThere Service via a link from another site, we may also track the source of that link.</p>
        <p><strong>Information Regarding Cookies:</strong>&nbsp;Like many other websites, the TapThere Service may send one or more cookies small text files containing a string of alphanumeric characters to your computer. TapThere may use cookies to track user traffic patterns as described above. TapThere may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the TapThere Service. Persistent cookies can be removed. You can refuse cookies by turning them off in your browser, however, some features on the TapThere Service may not function properly if your cookies are disabled. Please review your web browser Help file to modify your cookie settings.</p>
        <h3>Information we Obtain from Other Sources.</h3>
        <p>We may collect information about you from other sources, including from partners, and from commercially available sources (such as data aggregators and public databases). The following are examples of information we may collect from other sources:</p>
        <ol>
          <li>Data you authorize us to access through your use of Facebook Connect.</li>
        </ol>
        <p>In general, the types and amount of personally identifiable information we collect vary depending on the activity. TapThere endeavors to limit the collection of personally identifiable information to information we believe is minimally necessary to achieve our stated collection purposes.</p>
        <h3>The Way TapThere Uses Information</h3>
        <p>TapThere uses personally identifiable information to establish and enhance our relationship with you. TapThere may use the information that you provide, or that we collect, to operate, maintain, enhance, and deliver the various features and services found on the TapThere Service; as well as to track user-generated content and users to comply as a service provider with the DMCA and other applicable laws.</p>
        <p>TapThere uses all of the information that we collect from users to understand and analyze the usage trends and preferences of our users, to improve the way the TapThere Service works and looks, and to create new features and functionality. Further, TapThere may use automatically collected data to: (a) remember your information so that you will not have to re-enter it during your visit or the next time you visit TapThere; (b) monitor your participation in various sections of the TapThere Service; (c) customize our service to you, including by providing you with recommendations and (c) monitor aggregate site usage metrics such as total number of visitors and pages viewed.</p>
        <p>We may use your e-mail address for administrative communications such as notifying you of major TapThere Service updates, for customer service purposes, and to send privacy or security-related notices. In certain cases, we may contact you to offer to facilitate an introduction to another company or person, including companies with which we have business relationships. If you do not want to have us contact you for these purposes in the future, you can opt out.</p>
        <h3>How TapThere May Disclose Information</h3>
        <p>TapThere does not share your personally identifiable information with other organizations for their marketing or promotional uses without your prior consent.</p>
        <p>We may share your personally identifiable information with companies that provide services to us, including outside vendors, contractors, or agents who help us manage our business activities. Such entities only use your personal information in accordance with this Privacy Notice and for no other purpose than to provide us with necessary services.</p>
        <p>We may allow access to certain content, including personally identifiable information, through our application programming interface (API), as described below.</p>
        <div>
          <p><u>Public video and information access by applications</u></p>
          <p>If you have posted a video as public, certain applications (apps) may access and use, through our API, the video and other publicly available information associated with you (basic information):</p>
          <ul>
            <li>the public video (including its title, and public comments you include with the video);</li>
            <li>the name (or pseudonym) associated with your account;</li>
            <li>the profile image associated with you;</li>
            <li>the profile URL (which may include user ID) for the account.</li>
          </ul>
          <p>If you do not want developers to have access to and use of this basic information through our API, please mark your videos as private.</p>
          <p>Also, if you are OK with app access to and use of your video but do not want developers to have access to your real name or profile image (for example, if it is a picture of your face), please use a pseudonym or handle and use a profile image that does not consist of a picture of you.</p>
          <p><u>Authenticated access by applications</u></p>
          <p>Certain apps may want to do the following types of activities:</p>
          <ul>
            <li>Manage relationships: to manage user relationships such as follow, unfollow, block, and unblock people, and to approve people who want to follow you;</li>
            <li>Manage likes: to manage likes on your behalf; and</li>
            <li>Manage comments: to manage and posts comments on your behalf.</li>
          </ul>
          <p>We require the app developers to explain what actions they will take and also what information (including personally identifiable information) they need to access in order to take these actions, and to get your approval. In addition, we require developers to explain their privacy practices to you by providing a link to their privacy policy, privacy notice, or similar expression of their privacy practices.</p>
          <p><u>Limitations on access by applications</u></p>
          <p>In both cases described above, app developers are not allowed to use your personally identifiable information for marketing or promotional purposes, and are otherwise restricted in their use of your information by the terms of our&nbsp;<a href="http://tapthere.com/privacy#AppDevAgreement">App Developer Agreement</a>.</p>
        </div>
        <p>TapThere may disclose personally identifiable information if required to do so by law or under the good faith belief that such action is necessary to comply with state and federal laws (including, without limitation, U.S. copyright or trademark laws) or respond to a court order, subpoena, judicial or other governmental requirement or warrant in a manner required by the requesting entity.</p>
        <p>TapThere also reserves the right to disclose personally identifiable information that we believe, in good faith, is appropriate or necessary to take precautions against liability; enforce our Terms of Use; protect TapThere from fraudulent, abusive, or unlawful uses; to initiate, render, bill and collect for amounts owed to TapThere; to investigate and defend ourselves against third-party claims or allegations; to assist government enforcement agencies; to protect the security or integrity of the TapThere Service; or to protect the rights, property, or personal safety of TapThere, our Users, or others.</p>
        <p>In the event that TapThere is acquired by, merged with, or our assets are transferred to, another entity, such entity may be able to use your personal information under the terms of this Privacy Notice.</p>
        <h3>Access to Your Personal Information</h3>
        <p>If you register as a member of TapThere, you may update, correct, or delete your profile information and preferences at any time by e-mailing us privacy@ TapThere.com. To protect your privacy and security, we take reasonable steps to verify your identity before granting you account access or making corrections to your information. You are responsible for maintaining the secrecy of your unique password and account information at all times.</p>
        <h3>Children</h3>
        <p>TapThere does not knowingly collect or retain personally identifiable information about persons under 13 years of age. Any person who provides their personal information to TapThere through this website represents that they are 13 years of age or older. If TapThere learns that personally identifiable information of persons under the age of 13 years has been collected on the TapThere Service without verifiable parental consent, then TapThere will take the appropriate steps to delete this information.</p>
        <p>If you are a parent or guardian and discover that your child under the age of 13 has obtained a TapThere Service account, then you may alert TapThere at privacy@ TapThere.com and request that TapThere delete that childs personal information from its systems.</p>
        <h3>Data Security</h3>
        <p>TapThere uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your personal information. However, no data transmissions over the Internet can be guaranteed to be 100% secure. We cannot, therefore, ensure or warrant the security of any information you transmit to TapThere, and you do so at your own risk. Please know that although we make our best efforts to keep your information secure, this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>
        <p>If TapThere learns of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. By using this website or providing personal information to us you agree that we can communicate with you electronically regarding security, privacy and administrative issues relating to your use of this website. TapThere may post a notice on the TapThere Service if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a free written notice of a security breach, or to opt out of receiving electronic notice you should notify us at privacy@TapThere.com.</p>
        <h3>International Visitors</h3>
        <p>The TapThere Service is hosted in the United States. If you visit the TapThere Service from the European Union or other regions of the world with laws governing data collection and use that may differ from laws of the United States, then please understand that you are transferring your personally identifiable information outside of those jurisdictions to the United States for the uses identified above in accordance with this Privacy Notice. By providing your personally identifiable information on the TapThere Service you consent to that transfer.</p>
        <h3>Third Parties</h3>
        <p>Except as expressly provided otherwise, this document addresses only the use and disclosure of information that the TapThere Service collects from you or that you disclose to us. If you disclose your information to others besides TapThere different rules may apply to their use or disclosure of the information you disclose to them. TapThere does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. We encourage you to ask questions before you disclose your personal information to others.</p>
        <h3>Contact</h3>
        <p>Please contact TapThere with any questions or comments about this Privacy Notice, your personal information, our third-party disclosure practices, or your consent choices by e-mail at privacy@TapThere.com or by mail at TapThere Inc., Attn: Privacy Matters, 11878 La Grange 2<sup>nd</sup>&nbsp;Floor, Los Angeles, CA 90025.</p>
      </div>
    );
  }
}

export default privacyPolicy;