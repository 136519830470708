import React, { Component } from 'react';
import Hero from '../components/Hero';
import Register from '../components/Register';


class Home extends Component {
  render() {
    return (
      <div className="section">
        <Hero />
        <Register />
      </div>
    );
  }
}

export default Home;