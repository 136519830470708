import React, { Component } from 'react';
import SubHeader from '../components/SubHeader';

/* import contentData from '../content.json'; */

class About extends Component {
  render() {
    return (
      <div className="section">
        <SubHeader />
        <div className="content body-copy">
          <h1>About</h1>
          <p>Wormhole is an emerging technology platform, a powerful social community builder where The World is the Game™.</p>
          <p>Wormhole from here to there with enhanced teleporting as though you are actually there, bringing gamers together globally.</p>
          <p>Create and customize your own clubhouse to share content, engage with your audience in more intimate and immersive ways that grow monetization with digital and physical goods.  Wormholing enhances streaming and enables Wormholers to Step into the Stream™ and experience a clubhouse environment which combines both the virtual and real world.</p>
        </div>
      </div>
    );
  }
}

export default About;