import React, { Component } from 'react';
import { Link } from "@reach/router"

import SVG from 'react-inlinesvg';
import faInstagram from "../scss/icons/instagram-brands.svg";
import faTwitter from "../scss/icons/twitter-brands.svg";
import faTwitch from "../scss/icons/twitch-brands.svg";




import contentData from '../content.json';

class Footer extends Component {      
   render() {
      return (
    <footer id="footer" className="footer">
              
        <div className="footerinfo">

            <div className="col-left">
              <div className="logo">
                <img src={contentData.footer.footerLogo} className='logo' alt="logo" />
                </div>
            </div>

            <div className="col-right">
                <ul className="social">
                    <li> <a href="https://www.instagram.com/wormhole/" target="_blank" rel="noopener noreferrer" tabIndex={ -1}><SVG src={faInstagram} /></a> </li>
                    <li> <a href="https://twitter.com/wormhole" target="_blank" rel="noopener noreferrer" tabIndex={ -1}><SVG src={faTwitter} /> </a></li>
                    <li> <a href="https://www.twitch.tv/WormholeGG" target="_blank" rel="noopener noreferrer" tabIndex={ -1}><SVG src={faTwitch} /> </a></li>
                    <li className="textLink"> <Link to="privacy-policy"> {contentData.footer.privacyLinkCopy} </Link> </li>
                    <li className="textLink"> <Link to="terms-of-service"> {contentData.footer.tosLinkCopy} </Link>  </li>
                </ul>   
            </div>

            <div className="copyright">{contentData.footer.copyrightCopy}</div>

        </div>
        <div className="lense-flare-footer">
            <img src='images/lense-flare-footer.png' className='deviceImg' alt="logo" />  
        </div>
    
    </footer>
    );
  }
}
export default Footer;