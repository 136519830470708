import React, { Component } from 'react';
import $ from 'jquery';

import SVG from 'react-inlinesvg';
import chevronRight from "../scss/icons/chevron-right-light.svg";

import contentData from '../content.json';

class Register extends Component {
  constructor(props) {
    super(props);

    // Must initialize state first
    this.state = {
      username: "",
      email: "",
      password: ""
    };

    this.ApiUrl = process.env.REACT_APP_API_URL;

    // save base state
    this.baseState = this.state;

    this.handleInput = this.handleInput.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.isValid = this.isValid.bind(this);

  }


  isValid(target, val) {

    let valid = false;

    switch (target) {
      case 'password':
        let passRegEx = /(?=^.{8,30}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
        if (passRegEx.test(val)) {
          valid = true;
        }

        break;
      case 'email':
        // eslint-disable-next-line 
        let emailRegEx = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/;
        if (emailRegEx.test(val)) {
          valid = true;
        }

        break;
      case 'username':

        let userRegEx = /^([a-zA-Z0-9_-]){3,12}$/;
        if (userRegEx.test(val)) {
          valid = true;
        }

        break;

      default:
        valid = false;
    }
    return valid
  }

  resetForm() {
    $('.form').removeClass('sent error success user');
    this.setState(this.baseState)
  }

  handleInput(e) {

    let valid = this.isValid(e.target.name, e.target.value);

    if (valid) {
      this.setState({
        [e.target.name]: e.target.value
      });

      $('.warning').removeClass('on');

    }
    else {
      $('#' + e.target.name).addClass('on');
    }


  }

  handleForm() {

    if (this.state.password || this.state.email || this.state.username) {

      $('.form').addClass('sent');

      console.log('Connecting to: ' + this.ApiUrl);

      $.ajax({
        url: this.ApiUrl,
        type: "POST",
        headers: {
          "cache-control": "no-cache"
        },
        contentType: "application/json",
        data: JSON.stringify({
          "password": this.state.password,
          "email": this.state.email,
          "username": this.state.username
        })
      })
        .done(function (data, textStatus, jqXHR) {
          console.log("HTTP Request Succeeded: " + jqXHR.status);
          console.log(data);

          $('.form').addClass('sucess');

        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          console.log("HTTP Request Failed");

          let errResponse = JSON.parse(jqXHR.responseText);
          console.log(errResponse);

          let errMSg = errResponse.message + ' ';
          let err = errResponse.errors;

          Object.keys(err).map(function (keyName, keyIndex) {
            errMSg += err[keyName] + ' ';
            return false;
          });

          $('.form .response .error h4').html(errMSg);
          $('.form').addClass('error');
        });
    }
    else {
      $('.warning').addClass('on');
    }

  }

  render() {
    return (
      <div className="register">

        <div className="registerContain">

          <div className="device">
            <img src={contentData.register.registerImage} className='deviceImg' alt="logo" />
          </div>

          <div className="form">

            <div className="submit">
              <h4>{contentData.register.form.title}</h4>

              <div className="input">
                <div className="chevron"> <SVG src={chevronRight} /> </div>
                <input placeholder={contentData.register.form.inputs.username.placeholderCopy} name={"username"} onChange={this.handleInput} tabIndex={1}></input>
                <div id="username" className="warning">{contentData.register.form.inputs.username.errorCopy}</div>
              </div>

              <div className="input">
                <div className="chevron"><SVG src={chevronRight} /> </div>
                <input placeholder={contentData.register.form.inputs.email.placeholderCopy} name={"email"} onChange={this.handleInput} tabIndex={2}></input>
                <div id="email" className="warning">{contentData.register.form.inputs.email.errorCopy}</div>
              </div>

              <div className="input">
                <div className="chevron"><SVG src={chevronRight} /> </div>
                <input placeholder={contentData.register.form.inputs.password.placeholderCopy} type='password' name={"password"} onChange={this.handleInput} tabIndex={3}></input>
                <div id="password" className="warning">{contentData.register.form.inputs.password.errorCopy}</div>
              </div>

              <button onClick={this.handleForm} tabIndex={4}>{contentData.register.form.submitButtonLabel}</button>

            </div>

            <div className="response">

              <div className="trying">
                <h4>{contentData.register.form.responseMessages.connectingCopy}</h4>
              </div>

              <div className="success">
                <h4>{contentData.register.form.responseMessages.successCopy}</h4>
              </div>

              <div className="error">
                <h4>&nbsp;</h4>
                <button onClick={this.resetForm}>{contentData.register.form.responseMessages.tryAgainButtonLabel}</button>
              </div>

              <div className="userExists">
                <h4>{contentData.register.form.responseMessages.userExistsCopy}</h4>
                <button onClick={this.resetForm}>{contentData.register.form.responseMessages.tryAgainButtonLabel}</button>
              </div>

            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default Register;