import React, { Component } from 'react';
import Swiper from 'swiper';

import SVG from 'react-inlinesvg';
import chevronLeft from "../scss/icons/chevron-left-light.svg";
import chevronRight from "../scss/icons/chevron-right-light.svg";


/*import contentData from '../content.json';*/

class SlideContentPane extends Component {

  componentDidMount() {
    new Swiper('.swiper-container', {
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  render() {
    return (
      <div className="swiper">

        <div className="swiper-container">

          <div className="swiper-wrapper">
            <div className="swiper-slide"> <img src="slideshow/WH-V1-Slideshow_Grabs-1.jpg" alt="slide 1" /> <h3>The Virtual World + The Real World</h3> </div>
            <div className="swiper-slide"> <img src="slideshow/WH-V1-Slideshow_Grabs-2.jpg" alt="slide 1" /> <h3>Step Into The Stream</h3> </div>
            <div className="swiper-slide"> <img src="slideshow/WH-V1-Slideshow_Grabs-3.jpg" alt="slide 1" /> <h3>Become A Wormholer™</h3> </div>
            <div className="swiper-slide"> <img src="slideshow/WH-V1-Slideshow_Grabs-4.jpg" alt="slide 1" /> <h3>The World Is The Game™</h3> </div>
          </div>

        </div>

        <div className="swiper-button-next">
          <SVG className="chevron-right" src={chevronRight} />
        </div>

        <div className="swiper-button-prev">
          <SVG className="chevron-left" src={chevronLeft} />
        </div>

        <div className="swiper-pagination"></div>

      </div>

    );
  }
}

export default SlideContentPane;