import React, { Component }  from 'react';
import { Router } from "@reach/router"

// components --------------------------------------------------------
import Header from './components/Header';
import Menu from './components/Menu';
import Home from './components/Home';
import About from './components/About';
import SignUp from './components/SignUp';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

// json data object
import contentData from './content.json';

// swiper css
import 'swiper/dist/css/swiper.min.css';
// scss file
import './App.scss';

class UserFunel extends Component {
  constructor(props) {
    super(props);
    console.log('DATA OBJ:');
    console.log(contentData);
}

  render() {
    return (

      <div className="app">

        <div className="container">
            <Header />
            <Menu />
            <Router>
              <Home path="/" />
              <About path="about" />
              <SignUp path="sign-up" />
              <PrivacyPolicy path="privacy-policy" />
              <TermsOfService path="terms-of-service" />
            </Router>
        </div> 

        <Footer/>
        
    </div>

    );
  }
}
export default UserFunel;
