import React, { Component } from 'react';
import { Link } from "@reach/router"

import SVG from 'react-inlinesvg';
import menuIcon from "../scss/icons/bars-light.svg";
import closeIcon from "../scss/icons/times-light.svg";
import instagram from "../scss/icons/instagram-brands.svg";
import twitter from "../scss/icons/twitter-brands.svg";
import twitch from "../scss/icons/twitch-brands.svg";

import contentData from '../content.json';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      condition: !this.state.condition
    });
  }

  componentDidMount() {

    //console.log(this.state);

    /*
    let menuItems = [];
    let click = this.handleClick();

    // looping the reach/router Link element is not rendering correctly
    contentData.menu.forEach(function (item) {
      menuItems.push(<li><Link to={item.link} onClick={click} > {item.label} </Link></li>);
    });

    this.menuItems = menuItems;
    */
  }

  render() {
    return (
      <div className={this.state.condition ? "menu on" : "menu"}>

        <div className="menu-toggle">
          <div className="hamburger" onClick={this.handleClick} >  <SVG src={menuIcon} /> </div>
          <div className="close-menu" onClick={this.handleClick} > <SVG src={closeIcon} /> </div>
        </div>

        <div className="menu-container">

          <ul className="menu-links">
            <li><Link to="/" onClick={this.handleClick} >Home</Link></li>
            <li><Link to="about" onClick={this.handleClick} >About</Link></li>
            <li><Link to="sign-up" onClick={this.handleClick} >Sign Up</Link></li>
          </ul>

          <ul className="social">
            <li> <a href="https://www.instagram.com/wormhole/" onClick={this.handleClick} target="_blank" rel="noopener noreferrer" tabIndex={-1}><SVG src={instagram} /></a> </li>
            <li> <a href="https://twitter.com/wormhole" onClick={this.handleClick} target="_blank" rel="noopener noreferrer" tabIndex={-1}><SVG src={twitter} /> </a></li>
            <li> <a href="https://www.twitch.tv/WormholeGG" onClick={this.handleClick} target="_blank" rel="noopener noreferrer" tabIndex={-1}><SVG src={twitch} /> </a></li>
            <li className="textLink"> <Link to="privacy-policy" onClick={this.handleClick} > {contentData.footer.privacyLinkCopy} </Link> </li>
            <li className="textLink"> <Link to="terms-of-service" onClick={this.handleClick} > {contentData.footer.tosLinkCopy} </Link>  </li>
          </ul>

        </div>
      </div>

    );
  }
}

export default Menu;