import React, { Component } from 'react';

import contentData from '../content.json';


class Hero extends Component {
  constructor(props) {
    super(props);
    this.imgUrl = 'images/background-image.jpg';
    this.divStyle = {
      backgroundImage: 'url(' + this.imgUrl + ')',
    };
  }
  render() {
    return (
    <div className="hero sub-header" style={this.divStyle}>
      
      <div className="hero-container">

        <div className="hero-title">
          <img src={contentData.hero.headerImage} alt="The World is the Game" /> 
        </div>

        <h3>{contentData.hero.subTitleCopy}</h3>

      </div>


    </div>
    );
  }
}
export default Hero;