import React, { Component } from 'react';
import SubHeader from '../components/SubHeader';
import Register from '../components/Register';


class SignUp extends Component {
  render() {
    return (
      <div className="section">
        <SubHeader />
        <Register />
      </div>
    );
  }
}

export default SignUp;