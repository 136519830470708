import React, { Component } from 'react';
import contentData from '../content.json';

class VideoContentPane extends Component {

  render() {
    return (
      <div className="video">
        <iframe  title="Wormhole Trailer" src={contentData.videoContentPane.youtubeEmbedLink} 
                 frameBorder="0" 
                 allowFullScreen></iframe>
      </div>
    );
  }
}

export default VideoContentPane;